.heading {
    font-family: var(--font-primary);
    text-align: center;
    font-size: 12px;
    margin: 8px 0 0 0;
    color: white;
    opacity: 0.7;
    display: none;
}

@media screen and (max-width: 576px) {
    .heading {
        display: block;
    }
}