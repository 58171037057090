.removeMobile{
    display: block;
}
.slider{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}
.mainContainer {
    padding: 18px 0px 4.6vw;
    font-family: var(--font-primary);
    background-color: #ffffff;
}
.videoContainer {
    margin-top: 45px;
    max-width: 800px;
}
.videoMain {
    width: 100%;
    padding: 0 8px;
}
.sliderContainer {
    margin-top: 20px;
}
.slideComponent {
    cursor: pointer;
    margin: 0 8px;
}
.slideDivComponent {
    position: relative;
    width: 100%;
    height: 170px;
}
h3.subHeading {
    width: 85%;
    margin-top: 5px;
    font-size: clamp(18px, 1.45vw, 28px);
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: -0.45px;
    text-align: left;
    color: rgba(51, 51, 51, 0.85);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
p.paraComponent {
    opacity: 0.7;
    font-size: clamp(12px, 0.9vw, 18px);
    letter-spacing: -0.29px;
    color: rgba(51, 51, 51, 0.85);
}
h2.headComponent {
    font-size: clamp(16px, 3vw, 60px);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    text-align: center;
    color: #35255a;
}

@media screen and (max-width: 1200px){
    h3.subHeading {
        font-size: 22px;
    }
    p.paraComponent {
        font-size: 14px;
    }
    h2.headComponent {
        font-size: 35px;
    }
}
@media screen and (max-width: 767px) {
    .slideDivComponent {
        height: clamp(100px, 20.8vw, 400px);
    }
}
@media screen and (max-width: 576px) {
    .mainContainer {
        display: none !important;
    }
    .removeMobile{
        display: none;
    }
}