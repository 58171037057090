.modalContainer {
    display: none;
    flex-direction: column;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
}

.popupContainer {
    position: relative;
}

.bonusContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 30px 10px 30px;
}

.cancelButtonContainer {
    position: absolute;
    right: -14px;
    top: -13px;
    cursor: pointer;
}

.containerOpen {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    z-index: 10000000;
}

.containerOpenFirefox {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: rgba(102, 97, 97, 0.918);
    animation: fadeInForFirefox .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    z-index: 10000000;
}

.containerClose {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    visibility: hidden;
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}


@keyframes fadeInForFirefox {
    0% {
        transform: scale(0);
    }
    100% {

        transform: scale(1);
    }
}


@keyframes fadeIn {
    0% {
        backdrop-filter: blur(0px);
        transform: scale(0);
    }
    100% {
        backdrop-filter: blur(5px);
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        backdrop-filter: blur(30px);
        transform: scale(1);
    }
    25% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        backdrop-filter: blur(0px);
        transform: scale(0.5);
    }
}

@media screen and (max-width: 767px) {
    .modalContainer {
        display: flex !important;
    }
}